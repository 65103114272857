<template>
  <div>
    <section id="bgf5">
      <el-carousel ref="carousel" :interval="4000" arrow="always" height="90vh"
                   trigger="click" @mouseenter.native="delHandleMouseEnter">
        <el-carousel-item class="carous-img-1">
          <!-- <img src="../img/login/2.jpg" width="100%" /> -->
          <div class="carous-div">
            <div class="login-cp" style="color: #fff;font-weight: bold;"><span style="color:#fea229;">SPLM </span><span>产品全生命周期管理平台</span>
            </div>
            <div class="login-cp-1" style="color: #fff;font-weight: bold;line-height: 1.72;">
              <span>全面提升企业数字化协同管理能力</span></div>
            <div class="login-cp-2" style="color: #fff;line-height: 1.72;"><span>SPLM平台整合科研项目管理系统、产品数据管理系统、结构化工艺数据管理系统三大系统，两个工具，全面提升企业业务管理能力</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carous-img-4">
          <div class="carous-div">
            <div class="login-cp" style="color: #fff;font-weight: bold;"><span style="color:#fea229;">SPDM </span><span>产品数据管理系统</span>
            </div>
            <div class="login-cp-1" style="color: #fff;font-weight: bold;line-height: 1.72;">
              <span>全面提升企业数字化协同设计能力</span></div>
            <div class="login-cp-2" style="color: #fff;line-height: 1.72;"><span>通过使用SPDM系统，企业可以更好地管理产品的整个生命周期，提高产品质量、加速上市时间并降低成本</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carous-img-2">
          <!-- 	<img src="../img/login/4.jpg" width="100%" /> -->
          <div class="carous-div">
            <div class="login-cp" style="color: #fff;font-weight: bold;"><span style="color:#fea229;">SPMS </span><span>科研项目管理系统</span>
            </div>
            <div class="login-cp-1" style="color: #fff;font-weight: bold;line-height: 1.72;">
              <span>全面提升企业数字化协同设计能力</span></div>
            <div class="login-cp-2" style="color: #fff;line-height: 1.72;"><span>系统提供了与项目相关活动的准确的各角度视图，帮助科研人员更高效地规划、执行和监控科研项目，并提供了各种功能和工具来简化项目管理过程</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carous-img-3">
          <!-- 	<img src="../img/login/5.jpg" width="100%" /> -->
          <div class="carous-div">
            <div class="login-cp" style="color: #fff;font-weight: bold;"><span style="color:#fea229;">SMPM </span><span>结构化工艺数据管理系统</span>
            </div>
            <div class="login-cp-1" style="color: #fff;font-weight: bold;line-height: 1.72;">
              <span>打通设计到制造数字链路的结构化工艺数据管理系统</span></div>
            <div class="login-cp-2" style="color: #fff;line-height: 1.72;">
              <span>SMPM系统可以帮助制造企业实现工艺规划的自动化和标准化，提高制造效率、质量和一致性</span></div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carous-img-4">
          <!-- 	<img src="../img/login/3.jpg" width="100%" /> -->
          <div class="carous-div">
            <div class="login-cp" style="color: #fff;font-weight: bold;"><span
                style="color:#fea229;">SpeedDLink </span><span>设计工具集成软件</span>
            </div>
            <div class="login-cp-1" style="color: #fff;font-weight: bold;line-height: 1.72;">
              <span>设计工具与系统无缝集成</span></div>
            <div class="login-cp-2" style="color: #fff;line-height: 1.72;">
              <span>通过使用SpeedDLink集成工具，可以降低二次开发成本，提高工具集成效率</span></div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carous-img-3">
          <div class="carous-div">
            <div class="login-cp" style="color: #fff;font-weight: bold;"><span
                style="color:#fea229;">SpeedVue </span><span>轻量可视化软件</span>
            </div>
            <div class="login-cp-1" style="color: #fff;font-weight: bold;line-height: 1.72;">
              <span>三维模型在线浏览、批注及圈阅工具</span></div>
            <div class="login-cp-2" style="color: #fff;line-height: 1.72;">
              <span>通过SpeedVue轻量可视化软件可对模型进行轻量化处理，提供web端浏览、批注、圈阅功能</span></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </section>
    <section id="bgf6">
      <div class="row row-center">
        <div class="col-lg-12 kxyw">核心产品</div>
        <div class="col-lg-4">
          <router-link to="/">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div style="color:rgb(254, 162, 41);font-size: 26px;">SPLM</div>
              <div>产品全生命周期管理平台</div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4">
          <router-link to="/SPDM">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div style="color:rgb(254, 162, 41);font-size: 26px;">SPDM</div>
              <div>产品数据管理系统</div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4">
          <router-link to="/SPMS">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div style="color:rgb(254, 162, 41);font-size: 26px;">SPMS</div>
              <div>科研项目管理系统</div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4">
          <router-link to="/SMPM">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div style="color:rgb(254, 162, 41);font-size: 26px;">SMPM</div>
              <div>结构化工艺数据管理系统</div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4">
          <router-link to="/SCAXLink">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div style="color:rgb(254, 162, 41);font-size: 26px;">SpeedDLink</div>
              <div>设计工具集成软件</div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4">
          <router-link to="/S3DViewer">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div style="color:rgb(254, 162, 41);font-size: 26px;">SpeedVue</div>
              <div>轻量可视化软件</div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="row row-center-2">
        <video autoplay loop muted>
          <source src="../img/login/1.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="txt-content" style="width: 100%;">
          <div style="text-align: center;width: 100%;">
            <h1>关于我们</h1>
          </div>
          <div style="color: #fff;width: 80%;margin: auto;font-size: 18px;">
		<p style="text-indent: 2em;">
      东之林科技（深圳）有限公司隶属于深圳市斯贝达电子有限公司，后者成立于2009年。经过十五年发展，打下了坚实的产品基础，积累了丰富的行业经验，主要面向科研院所、电力、变频、新能源、服务器、轨道交通等领域的企业提供产品和技术服务。
      </p>
      <p style="text-indent: 2em;">在四川创立了分公司和工业软件研发中心。工业软件研发中心技术人员占比80%，以来自PTC和西门子等原厂实施顾问的实施经验，联合中北大学的科研技术能力打造公司核心竞争力，专业从事工业软件产品研发、技术服务、工业信息化技术咨询。
工业软件研发中心主要业务:航空航天、国防电子、高科技电子、装备、汽车、家用电器、通用机械等研发型和制造型企业提供专业的、整体的信息化应用解决方案，以协助客户提高产品创新能力、缩短产品研制周期、降低产品制造成本、加快供应链响应速度、改善售后服务质量以及提升企业管理效率。
		</p>
          </div>
          <div class="col-lg-12 col-sm-6 mt-1  flex_jc">
<!--            <div class="g_botton">-->
<!--              <router-link class="clearfix" to="/about">-->
<!--                <div class="g_bottonfl fl" style="float: left;">了解我们</div>-->
<!--                <div class="g_bottonfr fr"></div>-->
<!--              </router-link>-->
<!--            </div>-->
            <a
                href="/about"
                class='button'
            >
              <svg>
                <rect
                    x="0" y="0"
                    fill="none"
                    width="100%"
                    height="100%"
                />
              </svg>
              了解我们
            </a>
          </div>
        </div>

      </div>
      <!-- 信创认证 -->
      <div class="row row-center-1">
        <div class="col-lg-12 hzhb"><h1>信创认证</h1></div>

        <div class="col-lg-12"
             style="font-size: 16px;line-height: 30px;color: #999999;text-align: center;margin-bottom: 3vh;">国产自主可控
        </div>
        <div class="col-lg-3 col-sm-6 mb-5 flex_jc">
          <div data-animate-hover="2">
            <div class="animate-this">
              <a href="#">
                <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="../img/about/xc1.png">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 mb-5 flex_jc">
          <div data-animate-hover="2">
            <div class="animate-this">
              <a href="#">
                <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="../img/about/xc2.png">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 mb-5 flex_jc">
          <div data-animate-hover="2">
            <div class="animate-this">
              <a href="#">
                <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="../img/about/xc6.jpg">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 mb-5 flex_jc">
          <div data-animate-hover="2">
            <div class="animate-this">
              <a href="#">
                <img alt="Image placeholder" class="img-fluid rounded shadow aboutimg" src="../img/about/xc7.jpg">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-sm-6 mb-5 flex_jc">
<!--          <div class="g_botton">-->
<!--&lt;!&ndash;            <router-link class="clearfix" to="/partners">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="g_bottonfl fl" style="float: left;">了解更多</div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="g_bottonfr fr"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            </router-link>&ndash;&gt;-->
<!--            -->
<!--          </div>-->
          <a
              href="/partners"
              class='button'
          >
            <svg>
              <rect
                  x="0" y="0"
                  fill="none"
                  width="100%"
                  height="100%"
              />
            </svg>
            了解更多
          </a>
        </div>
      </div>
    </section>
    <!--悬浮按钮-->
    <!--    <section class="white_content">-->
    <!--      <a to="/experience">-->
    <!--        <div class="white_content-1">-->
    <!--          <img src="../img/lxwm1.png"/><br/>-->
    <!--          <span>联系我们</span>-->
    <!--        </div>-->
    <!--      </a>-->
    <!--    </section>-->
    <contact/>
    <Footer/>
  </div>
</template>

<script>
import Contact from '@/views/components/Contact.vue'

export default {
  components: {Contact},
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    delHandleMouseEnter() {
      this.$refs.carousel.handleMouseEnter = () => {
      }
    },
  },



}
</script>
<style scoped>
.t-center {
  text-align: center;
}

.swiper-slide {
  transform: scale(0.5);
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  transform: scale(1.2);
}

.imgtit {
  text-align: center;
  padding: 30px 0;
  font-size: 16px;
  font-weight: bold;
}

#bgf5 {
}

.p-sty {
  font-size: 2rem;
  color: #fff;
  font-family: fantasy;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.el-carousel__container {
  height: 95vh;
}

.carous-img-1 {
  background: url('../img/login/2.jpg') no-repeat;
  background-size: 100% 100%;
}

.carous-img-2 {
  background: url('../img/login/4.jpg') no-repeat;
  background-size: 100% 100%;
}

.carous-img-3 {
  background: url('../img/login/5.jpg') no-repeat;
  background-size: 100% 100%;
}

.carous-img-4 {
  background: url('../img/login/3.jpg') no-repeat;
  background-size: 100% 100%;
}

.carous-div {
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.row-center {
  width: 70%;
  margin: auto;
  padding-top: 10vh;
}

.row-center-1 {
  padding-top: 8vh;
  width: 70%;
  margin: auto;
  background: url('../img/login/6.jpg') no-repeat;
  background-size: 100% 100%;
  margin-top: 15vh;
}

.row-center-2 {
  /* padding-top: 2vh;
  width: 100%;
  margin: auto;
  background: url('img/login/1.gif') no-repeat;
  background-size: 100% 100%;
  margin-top: 15vh;
  min-height: 50vh; */
  padding-top: 2vh;
  width: 100%;
  margin: auto;
  margin-top: 15vh;
  min-height: 50vh;
  position: relative; /* 添加这行 */
}

.row-center-2 .txt-content {
  z-index: 1;
}

.row-center-2 video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 视频填充整个容器 */
}

.kxyw {
  font-size: 40px;
  line-height: 50px;
  color: #333;
  text-align: center;
  margin-bottom: 5vh;
}

.card {
  border: 1px solid #50b5ff;
  height: 250px;
  font-size: 24px;
  font-weight: 500;
}

/* .aboutimg{
  width: 216px;
  height: 80px;
} */
.aboutimg:hover {
  transition: 1s;
  transform: scale(1.1);
}

.flex_jc {
  display: flex;
  justify-content: center;
  text-align: center;
}

.hzhb {
  font-size: 40px;
  line-height: 50px;
  color: #333;
  text-align: center;
  margin-bottom: 1vh;
}

.g_botton {
  width: 180px;
  height: 46px;
  background: #3595f7;
  position: relative;
  margin: auto;
}

.g_botton a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 22px;
}

.g_bottonfl {
  color: #fff;
  font-size: 14px;
  line-height: 46px;
}

.g_bottonfr {
  position: absolute;
  top: 50%;
  right: 20px;
  border-left: 6px solid #fff;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  transform: translateY(-50%);
}

/* 悬浮样式 */
.white_content {
  position: fixed;
  right: 10px;
  bottom: 200px;
  z-index: 999;
  background: none;
}

.white_content-1 {
  background: #50b5ff;
  padding: 10px 20px;
  border-radius: 30%;
  text-align: center;
}

.login-cp {
  font-size: 1.875rem;
}

.login-cp-1 {
  font-size: 3.125rem;
}

.login-cp-2 {
  font-size: 1.125rem;
}

@media (max-width: 900px) {
  .login-cp {
    font-size: 24px;
  }

  .login-cp-1 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .login-cp-2 {
    font-size: 16px;
  }
}
.g_botton {
  //width: 180px;
  //height: 46px;
  //background: rgb(1, 162, 128);
  //position: relative;
  //margin: auto;
  display: grid;
  place-items: center;
}
.button {
  background: rgb(1, 162, 128);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  line-height: 2.5rem;
  max-width: 160px;
  width: 100%;
  letter-spacing: 0.3rem;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  transition: all 1s ease-in;
}

.button:hover {
  color: #FF9950;
}

.button svg {
  height: 40px;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}

.button rect {
  fill: none;
  stroke: #ffff;
  stroke-width: 5;
  stroke-dasharray: 450, 0;
  transition: all 0.5s linear;
}

.button:hover rect {
  stroke-width: 5;
  stroke-dasharray: 20, 300;
  stroke-dashoffset: 48;
  stroke: #FF9950;
  transition: all 2s cubic-bezier(
      0.22, 1, 0.25, 1
  );
}
</style>
